<template>
  <div class="esigns" :style="'height:'+windowHeight+'px'">
    <vue-esign
      ref="esign"
      style="
        width: 100%;

      "
      :style="'height:'+windowHeight+'px'"
      :height="windowHeight"
      :isCrop="isCrop"
      :lineWidth="lineWidth"
      :lineColor="lineColor"
      :bgColor.sync="bgColor"
    />

    <div class="btn" style=" ">
      <van-button type="primary" @click="handleReset">重置</van-button>
      <van-button type="info" @click="handleGenerate">确定</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      windowHeight: document.documentElement.clientHeight,
      isCrop: false
    };
  },
  created() {
    // this.$refs.esign;
    var canvas = (document.querySelector("canvas").style.height =
      document.documentElement.clientHeight);
    canvas.width = document.documentElement.clientWidth;
    canvas.height = document.documentElement.clientHeight;
    console.log(canvas);
  },
  methods: {
    handleReset() {
      // 清除
      this.$refs.esign.reset();
    },

    handleGenerate() {
      // 获取base64
      var _this = this;
      _this.$refs.esign
        .generate()
        .then(res => {
          // 转成文件
          var file = _this.dataURLtoFile(res);
          console.log("file:", file);
          //调用接口
          uploadFile(file).then(({ data }) => {
            console.log("data:", data);
          });
        })
        .catch(err => {
          _this.$toast(err);
        });
    },
    // 将base64转换为file
    dataURLtoFile(dataurl) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], "easign.jpg", { type: mime });
    }
  }
};
</script>

<style scoped>
.esigns {
  position: relative;
}
.van-button{
    line-height: 48px;
    height: 48px;
    width: 100px;
    margin-left: 20px;
}
.btn {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  position: absolute;
  top: 50%;

  transform: rotate(90deg);
}
</style>